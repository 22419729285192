import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import CaptionWithLink from "../../components/caption-with-link";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const BusinessServices = ({ data }) => {
  return (
    <>
      <Seo
        title="Business Services in North Central Pennsylvania"
        description="The Business Services cluster includes a wide range of services available to today’s businesses, from business support services to waste management"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="45%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Business Services</h2>
            <p>
              The Business Services cluster includes a wide range of services
              available to today’s businesses, from business support services to
              waste management.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Included in the cluster are radio stations, payroll services,
              public relations agencies, graphic design services and janitorial
              services to name a few.
            </p>

            <p>
              Services tend to focus around three sub-clusters: those industries
              that help businesses market their products; those that help in the
              day-to-day operations; and those that offer outside consulting
              services.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Business Services"
        image={data.pathwaysImage.sharp.image}
        caption={
          <CaptionWithLink
            caption="Laughing Owl Press Co, McKean County"
            courtesy="PA Wilds"
            url="https://pawilds.com"
          />
        }
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: business {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }

    careerImage: file(
      relativePath: { eq: "cowomen-pd5FVvQ9-aY-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "McKeanCounty-LaughingOwlPressCo-PAWilds.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "charles-deluvio-rRWiVQzLm7k-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "charles-deluvio-rRWiVQzLm7k-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default BusinessServices;
